exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-aligner-checklist-download-tsx": () => import("./../../../src/pages/aligner-checklist-download.tsx" /* webpackChunkName: "component---src-pages-aligner-checklist-download-tsx" */),
  "component---src-pages-blog-search-results-tsx": () => import("./../../../src/pages/blog/search-results.tsx" /* webpackChunkName: "component---src-pages-blog-search-results-tsx" */),
  "component---src-pages-contact-tsx": () => import("./../../../src/pages/contact.tsx" /* webpackChunkName: "component---src-pages-contact-tsx" */),
  "component---src-pages-dental-market-research-download-tsx": () => import("./../../../src/pages/dental-market-research-download.tsx" /* webpackChunkName: "component---src-pages-dental-market-research-download-tsx" */),
  "component---src-pages-dental-market-research-tsx": () => import("./../../../src/pages/dental-market-research.tsx" /* webpackChunkName: "component---src-pages-dental-market-research-tsx" */),
  "component---src-pages-design-tsx": () => import("./../../../src/pages/design.tsx" /* webpackChunkName: "component---src-pages-design-tsx" */),
  "component---src-pages-faq-tsx": () => import("./../../../src/pages/faq.tsx" /* webpackChunkName: "component---src-pages-faq-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-patents-tsx": () => import("./../../../src/pages/patents.tsx" /* webpackChunkName: "component---src-pages-patents-tsx" */),
  "component---src-pages-portal-tsx": () => import("./../../../src/pages/portal.tsx" /* webpackChunkName: "component---src-pages-portal-tsx" */),
  "component---src-pages-pricing-tsx": () => import("./../../../src/pages/pricing.tsx" /* webpackChunkName: "component---src-pages-pricing-tsx" */),
  "component---src-pages-privacy-tsx": () => import("./../../../src/pages/privacy.tsx" /* webpackChunkName: "component---src-pages-privacy-tsx" */),
  "component---src-pages-request-demo-tsx": () => import("./../../../src/pages/request-demo.tsx" /* webpackChunkName: "component---src-pages-request-demo-tsx" */),
  "component---src-pages-test-story-2-tsx": () => import("./../../../src/pages/test/story2.tsx" /* webpackChunkName: "component---src-pages-test-story-2-tsx" */),
  "component---src-pages-ultimate-aligners-checklist-tsx": () => import("./../../../src/pages/ultimate-aligners-checklist.tsx" /* webpackChunkName: "component---src-pages-ultimate-aligners-checklist-tsx" */),
  "component---src-pages-vision-tsx": () => import("./../../../src/pages/vision.tsx" /* webpackChunkName: "component---src-pages-vision-tsx" */),
  "component---src-templates-blog-article-tsx": () => import("./../../../src/templates/blog/article.tsx" /* webpackChunkName: "component---src-templates-blog-article-tsx" */),
  "component---src-templates-blog-index-tsx": () => import("./../../../src/templates/blog/index.tsx" /* webpackChunkName: "component---src-templates-blog-index-tsx" */),
  "component---src-templates-press-index-tsx": () => import("./../../../src/templates/press/index.tsx" /* webpackChunkName: "component---src-templates-press-index-tsx" */)
}

